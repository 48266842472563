<template>
  <a-modal
      v-drag-modal
      :title="title"
      :visible="visible"
      :confirm-loading="confirmLoading"
      :maskClosable="false"
      :width="800"
      @ok="handleOk"
      @cancel="visible = false"
  >
    <a-form-model ref="ruleForm" :model="form" :rules="rules">
      <a-form-model-item label="活动名称" v-bind="formItemLayout" prop="actionTitle">
        <a-input v-model="form.actionTitle" v-decorator="['actionTitle']" placeholder="请输入活动名称"/>
      </a-form-model-item>

      <a-form-model-item label="活动详情" v-bind="formItemLayout" prop="remark">
        <a-textarea v-model="form.remark" v-decorator="['remark']"></a-textarea>
      </a-form-model-item>
      <a-form-model-item label="是否有效" v-bind="formItemLayout" prop="status">
        <a-radio-group v-model="form.status" v-decorator="['status',
                   {initialValue: 1}]">
          <a-radio :value="1">有效</a-radio>
          <a-radio :value="0">无效</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <div class="flex" style="margin-left:120px;">
        <a-form-model-item label="活动日期" v-bind="formItemLayout" prop="validFrom">
          <a-date-picker v-model="form.beginTime" v-decorator="['validFrom']" style="padding-left:5px"/>
        </a-form-model-item>
        <a-form-model-item label="至" v-bind="formItemLayout" prop="validTo" style="margin-left:-50px;width:250px;">
          <a-date-picker v-model="form.endTime" v-decorator="['validTo']"/>
        </a-form-model-item>
      </div>
      <!---->
      <a-form-model-item ref="doorHeader" label="活动图片" v-bind="formItemLayout">
        <a-input v-model="form.images" v-decorator="['images']"
                 placeholder="可以直接输入图片路径（多个用逗号分隔）"/>
        <div class="clearfix">
          <a-upload
              name="file"
              list-type="picture-card"
              class="avatar-uploader"
              accept="image/jpg,image/jpeg,image/png,image/gif"
              withCredentials
              :multiple="true"
              :data="{ bucketType: 'public', uploadType: 'image' }"
              :headers="headers"
              :file-list="imageList"
              action="admin/upload/uploadImage"
              :before-upload="beforeUpload"
              @preview="handlePreview"
              @change="(info) => handleImageChange(info)"
          >
            <div>
              <a-icon type="plus"/>
              <div class="ant-upload-text">上传</div>
            </div>
          </a-upload>
          <!--  -->
          <a-modal
              v-drag-modal
              title="图片预览"
              :visible="previewVisible"
              :footer="null"
              @cancel="previewVisible = false"
          >
            <img alt="example" style="width: 100%" :src="previewImage"/>
          </a-modal>
        </div>
      </a-form-model-item>
      <a-form-model-item label="活动商品" v-bind="formItemLayout" prop="goodsIdList">
        <a-tag color="red" v-for="(goodsId,index) in goodsIdList" :key="goodsId" closable @close="deleteGoods(index)">
          {{ goodsId }}
        </a-tag>
        <a-button type="primary" @click="selectGoods" size="small">
          增加商品
        </a-button>
      </a-form-model-item>
    </a-form-model>

    <template slot="footer">
      <div class="modal-btn flex">
        <span class="flex_1">&nbsp;</span>
        <a-button type="primary" :loading="confirmLoading" @click="handleOk(1)">
          确定
        </a-button>
        <a-button type="danger" :loading="confirmLoading" @click="visible=false">
          取消
        </a-button>
      </div>
    </template>
    <select-goods-modal ref="selectGoodsModal" @onSelected="onGoodsSelected"/>
  </a-modal>
</template>

<script>
import {getBase64OfPreview} from '@/utils/common.js';
import {DICT} from "@/utils/dict";
import SelectGoodsModal from "./SelectGoodsModal";
import moment from "moment";

export default {
  name: "ModifyActionModal",
  components: {SelectGoodsModal},
  data() {
    return {
      dict: DICT,
      formItemLayout: {
        labelCol: {span: 6},
        wrapperCol: {span: 15},
      },
      mode: 'edit',
      title: "",
      visible: false,
      confirmLoading: false,
      previewVisible: false,
      form: {},
      imageList: [],
      previewImage: "",
      goodsIdList: [],
      rules: {
        actionTitle: [
          {
            required: true,
            message: "请输入活动名称",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    headers() {
      return {
        Authentication: this.$store.state.account.token,
      };
    },
  },
  methods: {
    handleImageChange(info) {
      this.imageList = info.fileList || [];
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
      this.loading = false;
    },
    selectGoods() {
      this.$refs.selectGoodsModal.showModal();
    },
    beforeUpload(file) {
      const isJpgOrPng =
          file.type === "image/jpeg" ||
          file.type === "image/png" ||
          file.type === "image/gif";
      if (!isJpgOrPng) {
        this.$message.error("只能支持JPEG/PNG/GIF图片!");
      }
      const isLt2M = file.size / 1024 / 1024 <= 3;
      if (!isLt2M) {
        this.$message.error("图片大小不能超过3MB!");
      }
      return isJpgOrPng && isLt2M;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview && !file.thumbUrl) {
        file.preview = await getBase64OfPreview(file.originFileObj);
      }
      this.previewImage = file.url || file.preview || file.thumbUrl;
      this.previewVisible = true;
    },
    async showModal(record, mode) {
      const that = this;

      this.visible = true;
      this.mode = mode || 'new'

      let array = [];
      if (this.mode == 'new') {
        this.title = "增加活动";
        this.form = {
          isTop: 0,
          status: 1,
          platform: 2,
          hotTop: 0
        };
        this.goodsIdList = []
      } else {
        this.title = "修改活动：" + record.actionTitle;
        let res = await this.$get('shop/action/info/' + record.id)
        this.form = {
          ...res.data,
        };

        this.goodsIdList = res.data?.goodsIdList || []
        array = this.convertFileList(res.data?.images)
      }
      this.imageList = array;
    },
    convertFileList(list = []) {
      const that = this;
      if (!list) {
        return [];
      }
      if (typeof list === 'string') {
        list = list.split(/[,，、\r\n;]/g);
      }
      let array = [];
      list.forEach((v) => {
        if (!v) return;
        let path = that.$helper.getFullImgPath(v);
        array.push({
          type: "image/png",
          name: v,
          uid: v,
          status: "done",
          thumbUrl: path,
          response: {data: path, code: 0},
        });
      });

      return array;
    },
    deleteGoods(index) {
      this.goodsIdList.splice(index, 1);
    },
    onGoodsSelected(item) {
      debugger
      if (!this.goodsIdList.includes(item.id)) {
        this.goodsIdList.push(item.id);
        this.$forceUpdate()
      }
    },
    handleOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.confirmLoading = true;
          this.form.images = this.imageList.map((item) => {
            return item.response.data;
          }).join(',');
          let params = {
            ...this.form,
            goodsIdList: this.goodsIdList
          }

          if (this.form.beginTime) {
            if (typeof this.form.beginTime === 'string') {
              if (this.form.beginTime.indexOf(' ') < 0) {
                params.beginTime = this.form.beginTime + ' 00:00:00'
              }
            } else {
              params.beginTime = this.form.beginTime.format('YYYY-MM-DD') + ' 00:00:00'
            }
          }
          if (this.form.endTime) {
            if (typeof this.form.endTime === 'string') {
              if (this.form.endTime.indexOf(' ') < 0) {
                params.endTime = this.form.endTime + ' 00:00:00'
              }
            } else {
              params.endTime = this.form.endTime.format('YYYY-MM-DD') + ' 00:00:00'
            }
          }
          this.$postJson("shop/action/save", params).then((res) => {
            this.visible = false;
            this.confirmLoading = false;
            this.$message.success("保存成功");
            this.$emit("success");
          }).catch(() => {
            this.confirmLoading = false;
          });
        } else {
          this.confirmLoading = false;
        }
      });
    }
  },
};
</script>
<style>
.mce-container iframe {
  height: 400px !important;
}
</style>
<style lang="less" scoped>
.ant-input-number {
  width: 100% !important;
}
</style>
